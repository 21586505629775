<template>
  <div
    id="platform"
    class="mobile:mt-[80px] mt-desktop:mt-[120px] block flex-col"
  >
    <div
      class="mobile:text-[32px] text-white tablet:text-[48px] font-bold text-center"
    >
      Площадки для промо
    </div>
    <div class="flex gap-4 mt-10">
      <div
        @click="prevSlide"
        class="flex cursor-pointer items-center justify-center h-13 w-13 bg-white rounded-full"
      >
        <img class="w-6 h-6" src="@/assets/icons/chevron-left.svg" alt="" />
      </div>
      <div
        @click="nextSlide"
        class="flex cursor-pointer items-center justify-center h-13 w-13 bg-white rounded-full"
      >
        <img class="w-6 h-6" src="@/assets/icons/chevron-right.svg" alt="" />
      </div>
    </div>
    <swiper
      :slides-per-view="3"
      :space-between="50"
      class="max-w-[100%] client-slider mt-15 flex items-start"
      @swiper="onSwiper"
      :breakpoints="{
        1240: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        320: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
      }"
      loop
      ref="platform"
      @slideChange="onSlideChange"
    >
      <swiper-slide
        v-for="(slide, i) in platform_list"
        :key="i"
        class="flex flex-col justify-center items-center w-full"
      >
        <img
          :src="slide.platform"
          class="mobile:w-[140px] tablet:w-auto"
          alt=""
        />
        <div class="flex flex-col mt-10 gap-6">
          <div class="flex mobile:flex-col tablet:flex-row items-center gap-3">
            <div
              class="flex py-2 mobile:w-[80px] tablet:w-[120px] bg-white text-black justify-center items-center mobile:text-lg tablet:text-3xl font-extrabold rounded-full"
            >
              {{ slide.account_amount }}
            </div>
            <div class="mobile:text-md tablet:text-2xl font-extrabold">
              {{ slide.isPublic ? "пабликов" : "аккаунтов" }}
            </div>
          </div>
          <div class="flex mobile:flex-col tablet:flex-row items-center gap-3">
            <div
              class="flex py-2 mobile:w-[80px] tablet:w-[120px] bg-white text-black justify-center items-center mobile:text-lg tablet:text-3xl font-extrabold rounded-full"
            >
              {{ slide.subs }}
            </div>
            <div class="mobile:text-md tablet:text-2xl font-extrabold">
              подписчиков
            </div>
          </div>
          <div class="flex mobile:flex-col tablet:flex-row items-center gap-3">
            <div
              class="flex py-2 mobile:w-[80px] tablet:w-[120px] bg-white text-black justify-center items-center mobile:text-lg tablet:text-3xl font-extrabold rounded-full"
            >
              {{ slide.mau }}
            </div>
            <div class="mobile:text-md tablet:text-2xl font-extrabold">
              месячный охват
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div
          class="flex flex-col items-center justify-center gap-8 mobile:mt-40 tablet:mt-15"
        >
          <div
            class="font-extrabold text-center mobile:text-lg tablet:text-3xl"
          >
            Другие площадки
          </div>
          <button
            class="border border-white mobile:py-2 tablet:py-4 w-full rounded-full hover:bg-white hover:text-black"
          >
            Связаться с менеджером
          </button>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { platform_list } from "@/collection/platforms";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

const platformSlider = ref(null),
  onSwiper = (swiper) => {
    platformSlider.value = swiper;
  },
  onSlideChange = () => {
    console.log("slide change");
  },
  prevSlide = () => {
    platformSlider.value.slidePrev();
  },
  nextSlide = () => {
    platformSlider.value.slideNext();
  };
</script>

<style lang="scss">
.client-slider {
  .swiper-wrapper {
    display: flex;
    align-items: flex-start;
  }
}
</style>
