<template>
  <a
    class="mobile:mt-10 tablet:mt-20 mx-auto flex mobile:w-full tablet:min-w-[250px] tablet:max-w-[400px] tablet:w-auto items-center justify-center gap-3 px-[32px] py-[18px] bg-white rounded-[64px] text-black mobile:text-[16px] tablet:text-[20px] font-semibold"
    :href="props.link"
    target="_blank"
  >
    {{ props.text }}
    <slot></slot>
    <svg
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.125 11.375L23.25 3.25M23.25 3.25H17.8333M23.25 3.25V8.66667M23.25 15.1667V20.5833C23.25 21.158 23.0217 21.7091 22.6154 22.1154C22.2091 22.5217 21.658 22.75 21.0833 22.75H5.91667C5.34203 22.75 4.79093 22.5217 4.3846 22.1154C3.97827 21.7091 3.75 21.158 3.75 20.5833V5.41667C3.75 4.84203 3.97827 4.29093 4.3846 3.8846C4.79093 3.47827 5.34203 3.25 5.91667 3.25H11.3333"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </a>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  link: {
    type: String,
    default: "",
  },
  text: {
    type: String,
    default: "",
  },
});
</script>

<style lang="scss"></style>
