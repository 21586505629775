export const price_list = {
  0: {
    name: "VK",
    tariff: [
      {
        name: "VK Эконом",
        price: "от 30.000 ₽ ",
        content:
          "Посев по сетке MDK и партнерским пабликам. Максимальный качественный охват для кампаний  с небольшим бюджетом.",
        views: "80-90k",
      },
      {
        name: "VK База",
        price: "от 100.000 ₽ ",
        content:
          "Посев по сетке MDK и партнерским пабликам. Нужно недорогое  и качественное продвижение  в короткие сроки? Это — база. В цену входит создание креатива.",
        views: "270-320k",
      },
      {
        name: "VK Premium",
        price: "от 300.000 ₽ ",
        content:
          "Посев по сетке MDK и партнерским пабликам. Оптимально  для полноценного продвижения в VK. В цену входит создание креатива и персональный менеджер на связи с 10:00 до 20:00 пн-пт",
        views: "800k-1m",
      },
      {
        name: "VK Extra",
        price: "от 1.000.000 ₽ ",
        content:
          "Посев по сетке MDK и партнерским пабликам. Идеальный вариант для больших промо-кампаний и бизнеса. В цену входит создание креатива, прогноз рекламной кампании, оповещение о публикациях, согласование пакета пабликов, подробный отчет и персональный менеджер на связи с 10:00 до 20:00 пн-пт.",
        views: "от 3м",
      },
    ],
  },
  1: {
    name: "Telegram",
    tariff: [
      {
        name: "TG Эконом",
        price: "от 50.000 ₽ ",
        content:
          "Посев по сетке MDK и партнерским пабликам. Максимальный качественный охват для кампаний  с небольшим бюджетом.",
        views: "80-120k",
      },
      {
        name: "TG База",
        price: "от 160.000 ₽ ",
        content:
          "Посев по сетке MDK и партнерским пабликам. Нужно недорогое и качественное продвижение в короткие сроки? Это — база. В цену входит создание креатива.",
        views: "280-320k",
      },
      {
        name: "TG Premium",
        price: "от 320.000 ₽ ",
        content:
          "Посев по сетке MDK и партнерским пабликам. Оптимально для полноценного продвижения в TG. В цену входит создание креатива и персональный менеджер на связи с 10:00 до 20:00 пн-пт.",
        views: "580k-630k",
      },
      {
        name: "TG Extra",
        price: "от 1.000.000 ₽ ",
        content:
          "Посев по сетке MDK и партнерским пабликам. Идеальный вариант для больших промо-кампаний и бизнеса. В цену входит создание креатива, прогноз рекламной кампании, оповещение о публикациях, согласование пакета пабликов, подробный отчет и персональный менеджер на связи с 10:00 до 20:00 пн-пт.",
        views: "от 1м",
      },
    ],
  },
};
