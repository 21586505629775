<template>
  <Modal
    v-show="showModal"
    @close-modal="showModal = false"
    :title="activePrice.title"
    :description="activePrice.description"
  />
  <div id="price" class="mobile:mt-[80px] desktop:mt-[160px] block flex-col">
    <div
      class="mobile:text-[32px] text-white tablet:text-[48px] font-bold text-center"
    >
      Купить рекламу
    </div>
    <div
      class="flex gap-3 mt-10 mobile:w-full desktop:w-auto mobile:overflow-scroll tablet:overflow-hidden mobile:pb-4"
    >
      <div
        v-for="(item, i) in price_list"
        :key="i"
        :class="{ active: priceIndex === +i }"
        @click="changePriceCategory(i)"
        class="flex py-3 price-item cursor-pointer px-8 border border-white rounded-full font-semibold text-lg"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="flex gap-4 mt-10">
      <div
        @click="prevSlide"
        class="flex cursor-pointer items-center justify-center h-13 w-13 bg-white rounded-full"
      >
        <img class="w-6 h-6" src="@/assets/icons/chevron-left.svg" alt="" />
      </div>
      <div
        @click="nextSlide"
        class="flex cursor-pointer items-center justify-center h-13 w-13 bg-white rounded-full"
      >
        <img class="w-6 h-6" src="@/assets/icons/chevron-right.svg" alt="" />
      </div>
    </div>
    <swiper
      :slides-per-view="3"
      :space-between="50"
      class="max-w-[100%] client-slider mt-15 flex items-start"
      @swiper="onSwiper"
      loop
      ref="price"
      :breakpoints="{
        1240: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
      }"
      speed="500"
      @slideChange="onSlideChange"
    >
      <swiper-slide
        v-for="(slide, i) in price_list[priceIndex].tariff"
        :key="i"
      >
        <div
          class="flex flex-col justify-center w-full bg-white rounded-xl p-6 h-[450px]"
        >
          <div
            class="flex py-2 px-5 bg-black text-white w-fit rounded-full font-medium"
          >
            {{ slide.name }}
          </div>
          <div
            class="text-black mobile:text-2xl tablet:text-3xl font-bold mt-4"
          >
            {{ slide.price }}
          </div>
          <div
            class="text-[#667085] mobile:text-base tablet:text-lg mt-4 price-text"
          >
            {{ truncate(slide.content) }}
          </div>
          <div
            @click="openModal(slide.name, slide.content)"
            class="cursor-pointer text-[#409EFF] mobile:text-base tablet:text-lg mt-2"
          >
            Читать подробнее
          </div>
          <hr class="mobile:my-4 tablet:my-4 desktop:mb-auto" />
          <div class="flex gap-2">
            <img src="@/assets/icons/eye.svg" alt="" />
            <div class="text-black mt-1">{{ slide.views }} просмотров</div>
          </div>
          <button
            onclick="Chatra('openChat', true)"
            class="bg-black text-white w-full py-4 mt-8 rounded-full font-semibold text-lg"
          >
            Заказать
          </button>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { price_list } from "@/collection/price";
import Modal from "../../components/ModalPrice.vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

const activePrice = {
    title: ref(""),
    description: ref(""),
  },
  truncate = (text) => {
    let truncatedText = text.slice(0, 85);
    if (truncatedText.charAt(truncatedText.length - 1) === " ") {
      truncatedText = truncatedText.slice(0, -1);
    }
    return truncatedText + "...";
  },
  showModal = ref(false),
  priceSlider = ref(null),
  priceIndex = ref(0),
  onSwiper = (swiper) => {
    priceSlider.value = swiper;
  },
  changePriceCategory = (i) => {
    priceIndex.value = +i;
    priceSlider.value.slideTo(0, 0, false);
  },
  onSlideChange = () => {
    console.log("slide change");
  },
  prevSlide = () => {
    priceSlider.value.slidePrev();
  },
  nextSlide = () => {
    priceSlider.value.slideNext();
  },
  openModal = (title, description) => {
    showModal.value = true;
    activePrice.title = title;
    activePrice.description = description;
  };
</script>

<style lang="scss">
.price-item.active {
  background: #fff;
  color: #000;
}
</style>
