<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="modal" @click.stop>
      <div class="close cursor-pointer" @click="$emit('close-modal')">
        <img src="@/assets/icons/modal-close.svg" />
      </div>
      <div class="text-black text-xl font-semibold">{{ props.title }}</div>
      <div class="text-black mt-6">{{ props.description }}</div>
    </div>
  </div>
</template>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);

  .modal {
    background-color: white;
    height: max-content;
    width: 500px;
    margin-top: 10%;
    padding: 20px 24px;
    border-radius: 20px;
    position: relative;
    .close {
      right: 16px;
      top: 16px;
      position: absolute;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 767px) {
  .modal-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .modal {
      margin-top: 0;
      margin-bottom: 72px;
      max-width: calc(100% - 32px);
    }
  }
}
</style>
<script setup>
import { defineProps } from "vue";

const props = defineProps({
  title: {
    type: String,
    default: "Тариф",
  },
  description: {
    type: String,
    default: "Описание тарифа",
  },
});
</script>
